import ContentLoader from "react-content-loader";

export enum VariantTypeId {
	darkGrey = "dark",
	default = "default",
}

interface VariantType {
	id: VariantTypeId;
	backgroundColor: string;
	foregroundColor: string;
}

const variants: Map<string, VariantType> = new Map([
	[
		VariantTypeId.darkGrey,
		{
			id: VariantTypeId.darkGrey,
			backgroundColor: "#343434",
			foregroundColor: "#8C8C8C",
		},
	],
	[
		VariantTypeId.default,
		{
			id: VariantTypeId.default,
			backgroundColor: "#343434",
			foregroundColor: "#ffffff",
		},
	],
]);

interface LoaderProps {
	variant?: VariantTypeId;
}
const Loader = (props: LoaderProps) => {
	const { variant } = props;

	const defaultVariant = variant ?
		variants.get(variant) :
		variants.get(VariantTypeId.default);

	return (
		<ContentLoader
			speed={1}
			width="100%"
			viewBox="0 0 1500 400"
			backgroundColor={`${defaultVariant?.backgroundColor}`}
			foregroundColor={`${defaultVariant?.foregroundColor}`}
		>
			<rect x="27" y="39" rx="4" ry="4" width="20" height="20" />
			<rect x="67" y="40" rx="10" ry="10" width="85" height="19" />
			<rect x="188" y="41" rx="10" ry="10" width="169" height="19" />
			<rect x="402" y="40" rx="10" ry="10" width="85" height="19" />
			<rect x="523" y="41" rx="10" ry="10" width="169" height="19" />
			<rect x="731" y="39" rx="10" ry="10" width="85" height="19" />
			<rect x="852" y="38" rx="10" ry="10" width="85" height="19" />
			<rect x="1424" y="37" rx="10" ry="10" width="68" height="19" />
			<rect x="26" y="96" rx="4" ry="4" width="20" height="20" />
			<rect x="66" y="97" rx="10" ry="10" width="85" height="19" />
			<rect x="187" y="98" rx="10" ry="10" width="169" height="19" />
			<rect x="401" y="97" rx="10" ry="10" width="85" height="19" />
			<rect x="522" y="98" rx="10" ry="10" width="169" height="19" />
			<rect x="730" y="96" rx="10" ry="10" width="85" height="19" />
			<rect x="851" y="95" rx="10" ry="10" width="85" height="19" />

			<rect x="1424" y="95" rx="10" ry="10" width="68" height="19" />
			<rect x="26" y="158" rx="4" ry="4" width="20" height="20" />
			<rect x="66" y="159" rx="10" ry="10" width="85" height="19" />
			<rect x="187" y="160" rx="10" ry="10" width="169" height="19" />
			<rect x="401" y="159" rx="10" ry="10" width="85" height="19" />
			<rect x="522" y="160" rx="10" ry="10" width="169" height="19" />
			<rect x="730" y="158" rx="10" ry="10" width="85" height="19" />
			<rect x="851" y="157" rx="10" ry="10" width="85" height="19" />
			<rect x="1424" y="156" rx="10" ry="10" width="68" height="19" />

			<rect x="26" y="216" rx="4" ry="4" width="20" height="20" />
			<rect x="66" y="217" rx="10" ry="10" width="85" height="19" />
			<rect x="187" y="218" rx="10" ry="10" width="169" height="19" />
			<rect x="401" y="217" rx="10" ry="10" width="85" height="19" />
			<rect x="522" y="218" rx="10" ry="10" width="169" height="19" />
			<rect x="730" y="216" rx="10" ry="10" width="85" height="19" />
			<rect x="851" y="215" rx="10" ry="10" width="85" height="19" />
			<rect x="1424" y="215" rx="10" ry="10" width="68" height="19" />

			<rect x="26" y="279" rx="4" ry="4" width="20" height="20" />
			<rect x="66" y="280" rx="10" ry="10" width="85" height="19" />
			<rect x="187" y="281" rx="10" ry="10" width="169" height="19" />
			<rect x="401" y="280" rx="10" ry="10" width="85" height="19" />
			<rect x="522" y="281" rx="10" ry="10" width="169" height="19" />
			<rect x="730" y="279" rx="10" ry="10" width="85" height="19" />
			<rect x="851" y="278" rx="10" ry="10" width="85" height="19" />
			<rect x="1424" y="278" rx="10" ry="10" width="68" height="19" />

			<rect x="978" y="38" rx="10" ry="10" width="169" height="19" />
			<rect x="977" y="95" rx="10" ry="10" width="169" height="19" />
			<rect x="977" y="157" rx="10" ry="10" width="169" height="19" />
			<rect x="977" y="215" rx="10" ry="10" width="169" height="19" />
			<rect x="977" y="278" rx="10" ry="10" width="169" height="19" />
			<rect x="1183" y="39" rx="10" ry="10" width="85" height="19" />
			<rect x="1182" y="96" rx="10" ry="10" width="85" height="19" />
			<rect x="1182" y="158" rx="10" ry="10" width="85" height="19" />
			<rect x="1182" y="216" rx="10" ry="10" width="85" height="19" />
			<rect x="1182" y="279" rx="10" ry="10" width="85" height="19" />
			<rect x="1305" y="37" rx="10" ry="10" width="85" height="19" />
			<rect x="1304" y="94" rx="10" ry="10" width="85" height="19" />
			<rect x="1304" y="156" rx="10" ry="10" width="85" height="19" />
			<rect x="1304" y="214" rx="10" ry="10" width="85" height="19" />
			<rect x="1304" y="277" rx="10" ry="10" width="85" height="19" />
		</ContentLoader>
	);
};

export default Loader;
