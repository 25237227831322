import ImageFallback from "@components/core/Image/ImageFallback";
import { TableCell, TableRow } from "@components/core/Table";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { Play } from "@components/interaction";
import { DeleteConfirmationModal } from "@components/modals/DeleteConfirmationModal";
import { ArtistNames, renderArtistNames } from "@components/shared/Artists/ArtistNames";
import LabelLink from "@components/shared/Labels/LabelLink";
import { TRACK_PLACEHOLDER } from "@lib/constants";
import { usePlayerState } from "@lib/context/player";
import { cls } from "@lib/css";
import { Track } from "@models/track";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React, { useState } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import {
	ArtworkAndControls,
	Exclusive,
	ItemButtons,
	ItemControls,
	ReleaseName,
	TrackNo,
} from "../TracksTable.style";

const ChartsTracksTableItem: React.FC<{
	track: Track;
	showNumbers: boolean;
	chartDeleteTrack?: (trackId: number) => void;
	index: number;
	dragHandleProps?: DraggableProvidedDragHandleProps;
	location?: string;
}> = ({ track, showNumbers, index, chartDeleteTrack, dragHandleProps, location }) => {
	const IMG_WIDTH = TRACK_PLACEHOLDER.image.width;
	const IMG_HEIGHT = TRACK_PLACEHOLDER.image.height;
	const FALLBACK_URL = TRACK_PLACEHOLDER.href;
	const { currentTrack } = usePlayerState();
	const { t } = useTranslation("translation");
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

	const handleDeleteTrack = (trackid: number) => {
		if (!chartDeleteTrack) {
			return;
		}
		chartDeleteTrack(trackid);
		setShowDeleteConfirmationModal(false);
	};

	return (
		<>
			<DeleteConfirmationModal
				show={showDeleteConfirmationModal}
				header={t("Charts.MyDjCharts.Modal.DeleteTrackHeader")}
				confirmation={t("Charts.MyDjCharts.Modal.DeleteTrackConfirm")}
				onClose={() => setShowDeleteConfirmationModal(false)}
				onDelete={() => handleDeleteTrack(track.id)}
			/>
			<TableRow
				className={cls(
					"row",
					currentTrack && currentTrack.id === track.id ? "selected" : undefined,
				)}
			>
				<TableCell
					className="controls"
					style={{ minWidth: "120px", width: "10%" }}
				>
					<ArtworkAndControls>
						{showNumbers && <TrackNo className="active">{index + 1}</TrackNo>}
						<Link
							href={`/release/${track.release.slug}/${track.release.id}`}
							prefetch={false}
							title={track.release.name}
							className="artwork"
						>
							<ImageFallback
								src={track.release.image?.uri}
								srcSize="sm"
								alt={track.release.name}
								width={IMG_WIDTH}
								height={IMG_HEIGHT}
								fallbackSrc={FALLBACK_URL}
								blurDataURL={FALLBACK_URL}
								placeholder="blur"
							/>
							{track.exclusive && <Exclusive>{`${t("Exclusive")}`}</Exclusive>}
						</Link>
						<span className="fade">
							<Play tracks={[track]} />
						</span>
					</ArtworkAndControls>
				</TableCell>
				<TableCell
					className="cell title chart-cell"
					style={{ maxWidth: "15rem" }}
				>
					<div className="container" style={{ maxWidth: "10rem" }}>
						<Link
							href={`/track/${track.slug}/${track.id}`}
							prefetch={false}
							title={track.name}
						>
							<ReleaseName>
								{track.name} <span>{track.mix_name}</span>{" "}
							</ReleaseName>
						</Link>
						{track.artists.length > 0 && (
							<ArtistNames>{ renderArtistNames(track.artists, { location }) }</ArtistNames>
						)}
					</div>
				</TableCell>
				<TableCell
					className="cell genre chart-cell"
					style={{
						width: "20%",
						minWidth: "4rem",
						overflow: "hidden",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
					}}
				>
					<Link
						href={`/genre/${track.genre.slug}/${track.genre.id}`}
						prefetch={false}
						title={track.genre.name}
					>
						{track.genre.name}
					</Link>
					<div>{track.length}</div>
				</TableCell>
				<TableCell
					className="cell bpm chart-cell"
					style={{
						width: "20%",
						minWidth: "5rem",
						overflow: "hidden",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
					}}
				>
					<div>{track.key?.name}</div>
					<div>{track.bpm} BPM</div>
				</TableCell>
				<TableCell
					className="cell label chart-cell"
					style={{
						width: "20%",
						minWidth: "5rem",
						overflow: "hidden",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
					}}
				>
					<LabelLink
						label={track.release.label}
						location={location}
						withMarquee
					/>
					<div>{track.publish_date}</div>
				</TableCell>
				<TableCell
					className="cell card chart-cell"
					style={{ width: "3rem", minWidth: "30px", padding: "0" }}
				>
					<ItemControls>
						<ItemButtons className="borderless">
							{dragHandleProps && (
								<button {...dragHandleProps}>
									<SpriteIcon id="re-order" />
								</button>
							)}
							<button
								onClick={() => setShowDeleteConfirmationModal(true)}
								style={{ padding: "10px" }}
							>
								<SpriteIcon id="close-x" />
							</button>
						</ItemButtons>
					</ItemControls>
				</TableCell>
			</TableRow>
		</>
	);
};

export default ChartsTracksTableItem;
